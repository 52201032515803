// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-451-tsx": () => import("./../../../src/pages/451.tsx" /* webpackChunkName: "component---src-pages-451-tsx" */),
  "component---src-pages-alerts-tsx": () => import("./../../../src/pages/alerts.tsx" /* webpackChunkName: "component---src-pages-alerts-tsx" */),
  "component---src-pages-borrow-crypto-tsx": () => import("./../../../src/pages/borrow/crypto.tsx" /* webpackChunkName: "component---src-pages-borrow-crypto-tsx" */),
  "component---src-pages-borrow-tsx": () => import("./../../../src/pages/borrow.tsx" /* webpackChunkName: "component---src-pages-borrow-tsx" */),
  "component---src-pages-borrow-vigor-tsx": () => import("./../../../src/pages/borrow/vigor.tsx" /* webpackChunkName: "component---src-pages-borrow-vigor-tsx" */),
  "component---src-pages-health-tsx": () => import("./../../../src/pages/health.tsx" /* webpackChunkName: "component---src-pages-health-tsx" */),
  "component---src-pages-history-tsx": () => import("./../../../src/pages/history.tsx" /* webpackChunkName: "component---src-pages-history-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-rewards-insure-tsx": () => import("./../../../src/pages/rewards/insure.tsx" /* webpackChunkName: "component---src-pages-rewards-insure-tsx" */),
  "component---src-pages-rewards-savings-tsx": () => import("./../../../src/pages/rewards/savings.tsx" /* webpackChunkName: "component---src-pages-rewards-savings-tsx" */),
  "component---src-pages-rewards-tsx": () => import("./../../../src/pages/rewards.tsx" /* webpackChunkName: "component---src-pages-rewards-tsx" */),
  "component---src-pages-user-tsx": () => import("./../../../src/pages/user.tsx" /* webpackChunkName: "component---src-pages-user-tsx" */)
}

